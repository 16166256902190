import(/* webpackMode: "eager", webpackExports: ["FooterDraft"] */ "/vercel/path0/components/footer/draft.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Logo"] */ "/vercel/path0/components/footer/logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderClient"] */ "/vercel/path0/components/header/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderDraft"] */ "/vercel/path0/components/header/draft.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ImageInlineClient"] */ "/vercel/path0/components/image/client.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
